<template>
  <v-container class="my-6">
    <v-card>
      <v-card-title>Lista de deseos</v-card-title>
      <v-data-table
        :headers="headers"
        :items="wishlist"
      >
        <template v-slot:[`item.actions`]="{ item }" class="px-0 mx-0">
          <v-row justify-center>
            <v-col md="3">
              <v-icon
                color="secondary"
                icon
                title="Quitar de la lista de deseos"
                @click="removeOfWishlist(item)"
              >
                mdi-playlist-remove
              </v-icon>
            </v-col>
            <v-col md="3">
              <v-icon
                color="primary"
                icon
                title="Agregar al carrito"
                @click="addToCart(item)"
              >
                mdi-cart-plus
              </v-icon>
            </v-col>
          </v-row>
          
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  metaInfo: {
    title: 'Mi lista de deseos'
  },
  data() {
    return {
      headers: [
        { text: 'SKU', value: 'producto.sku', sortable: false },
        { text: 'Nombre del producto', value: 'producto.nombre_producto', sortable: false },
        { text: 'Descripción', value: 'producto.descripcion_corta', sortable: false },
        { text: 'Precio', value: 'producto.precio', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      wishes: [],
    }
  },
  computed: {
    ...mapState('usuario', ['wishlist'])
  },
  async created() {
    await this.getWishlistWithPrice()
  },
  methods: {
    ...mapMutations('cart', ['addProduct']),
    ...mapActions('usuario', ['getWishlistWithPrice', 'removeProductOfWishlist']),
    ...mapActions('products', ['setInWishlistFlag', 'setInWishlistFlagFeaturedProducts']),
    removeOfWishlist(product) {
      this.setInWishlistFlag(false)
      this.setInWishlistFlagFeaturedProducts(false)
      this.removeProductOfWishlist(product.producto.id)
      this.getWishlistWithPrice()
    },
    addToCart(product) {
      this.addProduct(product)
    }
  }
}
</script>

<style>
.v-data-table-header tr th {
  background-color: #FF3700 !important;
}
.v-data-table-header tr th span {
  color: white !important;
}
</style>